const tagGroups = [
  {
    id: '1',
    tags: ['9', '10', '9-1'],
  },
  {
    id: '2',
    tags: ['12', '12-1'],
  },
  {
    id: '3',
    tags: ['13', '13-1', '13-2'],
  },
  {
    id: '4',
    tags: ['14', '14-1', '14-2'],
  },
  {
    id: '5',
    tags: ['17', '17-1', '17-2'],
  },
  {
    id: '6',
    tags: ['22', '22-1', '22-2', '22-3'],
  },
  {
    id: '7',
    tags: ['3', '3-1', '3-2'],
  },
  {
    id: '8',
    tags: ['20', '20-1'],
  },
  {
    id: '9',
    tags: ['5', '5-1'],
  },
  {
    id: '10',
    tags: ['4', '4-1'],
  },
  {
    id: '11',
    tags: ['6', '6-1', '6-2'],
  },
  {
    id: '12',
    tags: ['7-1', '7-2'],
  },
  {
    id: '13',
    tags: ['41', '41-1'],
  },
  {
    id: '14',
    tags: ['39', '39-1'],
  },
  {
    id: '15',
    tags: ['33', '33-1'],
  },
  {
    id: '16',
    tags: ['31', '31-1'],
  },
  {
    id: '17',
    tags: ['28', '28-1', '28-2'],
  },
  {
    id: '18',
    tags: ['26', '26-1'],
  },
];
export default tagGroups;
