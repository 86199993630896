import React, { memo } from 'react';

import { Line } from '@react-three/drei';

function TagLine(props) {
  return (
    <Line
      points={[
        [0, 0, 0],
        [0, 0, 0.3],
      ]}
      color="#ecf0f1"
      lineWidth={2}
      transparent
      renderOrder={1}
      depthWrite={false}
    />
  );
}

export default memo(TagLine);
