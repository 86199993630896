import React, { memo, useLayoutEffect, useMemo, useRef } from 'react';

// import { Shape } from 'three';
import { useTexture } from '@react-three/drei';

const ImagePlane = (props) => {
  const { rotation, position, source, scale, color, shape, planeArgs } = props;
  // const planeArgs = { width: 2, height: 3.75 };
  const shapeRef = useRef();
  const texture = useTexture(source);

  // const shape = useMemo(() => {
  //   const _shape = new Shape();
  //   const x = -(planeArgs.width / 2);
  //   const y = -(planeArgs.height / 2);
  //   const radius = 0.08;
  //   const r = x + planeArgs.width;
  //   const b = y + planeArgs.height;

  //   _shape.moveTo(x + radius, y);
  //   _shape.lineTo(r - radius, y);
  //   _shape.quadraticCurveTo(r, y, r, y + radius);
  //   _shape.lineTo(r, y + planeArgs.height - radius);
  //   _shape.quadraticCurveTo(r, b, r - radius, b);
  //   _shape.lineTo(x + radius, b);
  //   _shape.quadraticCurveTo(x, b, x, b - radius);
  //   _shape.lineTo(x, y + radius);
  //   _shape.quadraticCurveTo(x, y, x + radius, y);

  //   return _shape;
  // }, []);

  useLayoutEffect(() => {
    function recalcUVs() {
      if (shapeRef.current) {
        shapeRef.current.geometry.computeBoundingBox();

        let max = shapeRef.current.geometry.boundingBox.max;
        let min = shapeRef.current.geometry.boundingBox.min;

        for (let i = 0; i < shapeRef.current.geometry.attributes.uv.count; i++) {
          let u = shapeRef.current.geometry.attributes.uv.getX(i);
          let v = shapeRef.current.geometry.attributes.uv.getY(i);

          shapeRef.current.geometry.attributes.uv.setXY(
            i,
            0.5 - u / (max.x - min.x),
            0.5 + v / (max.y - min.y)
          );
        }
        shapeRef.current.geometry.uvsNeedUpdate = true;
      }
    }

    recalcUVs();

    // for some reason, the UVs reset on resize.
    window.addEventListener('resize', recalcUVs);
    console.log('render Image', source);

    return () => {
      window.removeEventListener('resize', recalcUVs);
    };
  }, []);

  return (
    <group rotation={[0, rotation.y, 0]} position={position} scale={scale}>
      <group rotation={[rotation.x, 0, 0]}>
        <mesh ref={shapeRef} renderOrder={2}>
          <shapeGeometry args={[shape]} />
          <meshBasicMaterial map={texture} transparent toneMapped={false} />
        </mesh>
        <mesh renderOrder={1}>
          <planeGeometry args={[planeArgs.width + 0.1, planeArgs.height + 0.1]} />
          <meshBasicMaterial color={color || '#e0cbac'} depthWrite={false} transparent />
        </mesh>
      </group>
    </group>
  );
};

export default memo(ImagePlane);
