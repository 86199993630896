import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Vector3 } from 'three';

import TagIcon from './TagIcon';
import TagLine from './TagLine';

const TagInstance = (props) => {
  const { tag } = props;
  const groupRef = useRef();
  const vec3 = useMemo(() => new Vector3(), []);

  useLayoutEffect(() => {
    if (tag?.face && groupRef?.current) {
      let faceNormal = tag.face.isVector3 ? tag.face : vec3.set(tag.face.x, tag.face.y, tag.face.z);
      groupRef.current.position.set(0, 0, 0);
      groupRef.current.lookAt(faceNormal);
      groupRef.current.position.set(tag.position.x, tag.position.y, tag.position.z);
    }
  }, []);

  return (
    <group ref={groupRef}>
      <TagIcon tag={tag} />
      <TagLine />
    </group>
  );
};

export default TagInstance;
