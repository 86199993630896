import React, { Suspense, memo, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useTexture, useVideoTexture } from '@react-three/drei';

import resourceManager from 'src/services/resource.manager';

const VideoPlane = (props) => {
  const { rotation, position, source, scale, color, shape, planeArgs, storeId } = props;
  const [videoSource, setVideoSource] = useState();
  const shapeRef = useRef();
  // const texture = useVideoTexture(source);

  useLayoutEffect(() => {
    function recalcUVs() {
      if (shapeRef?.current) {
        shapeRef.current.geometry.computeBoundingBox();

        let max = shapeRef.current.geometry.boundingBox.max;
        let min = shapeRef.current.geometry.boundingBox.min;

        for (let i = 0; i < shapeRef.current.geometry.attributes.uv.count; i++) {
          let u = shapeRef.current.geometry.attributes.uv.getX(i);
          let v = shapeRef.current.geometry.attributes.uv.getY(i);

          shapeRef.current.geometry.attributes.uv.setXY(
            i,
            0.5 - u / (max.x - min.x),
            0.5 + v / (max.y - min.y)
          );
        }
        shapeRef.current.geometry.uvsNeedUpdate = true;
      }
    }

    recalcUVs();

    // for some reason, the UVs reset on resize.
    window.addEventListener('resize', recalcUVs);
    // console.log('render Video', source);

    return () => {
      window.removeEventListener('resize', recalcUVs);
    };
  }, []);

  const getMediaSource = async (mediaSource) => {
    const url = await resourceManager.getResource(storeId, mediaSource);
    if (url) {
      return url;
    }
  };

  useEffect(() => {
    const cacheResource = async (source) => {
      let tmpVideo = await getMediaSource(source);
      setVideoSource(tmpVideo);
    };

    if (source) {
      cacheResource(source);
    }
  }, [source]);

  return (
    <group rotation={[0, rotation.y, 0]} position={position} scale={scale}>
      <group rotation={[rotation.x, 0, 0]}>
        <mesh ref={shapeRef} renderOrder={2} scale={[-1, 1, 1]}>
          <shapeGeometry args={[shape]} />
          <Suspense fallback={<FallbackMaterial url="./assets/imgs/fidelity-load.jpg" />}>
            <VideoMaterial url={videoSource && videoSource} />
          </Suspense>
          {/* <meshBasicMaterial map={texture} transparent toneMapped={false} /> */}
        </mesh>
        <mesh renderOrder={1}>
          <planeGeometry args={[planeArgs.width + 0.1, planeArgs.height + 0.1]} />
          <meshBasicMaterial color={color || '#e0cbac'} depthWrite={false} transparent />
        </mesh>
      </group>
    </group>
  );
};

function VideoMaterial({ url }) {
  const texture = useVideoTexture(url);
  return <meshBasicMaterial map={texture} transparent toneMapped={false} />;
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url);
  return <meshBasicMaterial map={texture} transparent toneMapped={false} />;
}

export default memo(VideoPlane);
