import axios from 'axios';
import Papa from 'papaparse';
import React, { forwardRef, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import useOnlineStatus from 'src/hooks/useOnlineStatus';
import resourceManager from 'src/services/resource.manager';

import CloseIconButton from '../styled-components/CloseIconButton';
import LinkButton from '../styled-components/LinkButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    // flexGrow: 1,
    position: 'relative',
    // minWidth: 850,
    maxWidth: 900,
    padding: theme.spacing(2.5, 0),
    maxHeight: '90%',
    height: 630,
  },
  productContainer: {
    padding: theme.spacing(0, 2.5),
  },
  imageDetails: {
    // fontSize: 14,
    // padding: theme.spacing(0, 2.5),
  },
  poweredBy: {
    textAlign: 'right',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    fontSize: 14,
  },
  productName: {
    maxWidth: 285,
  },
  productImageContainer: {
    maxWidth: 285,
    width: '100%',
  },
  productImage: {
    outline: 'none',
    // maxWidth: 285,
    display: 'block',
    margin: 'auto',
    width: '100%',
  },
  productPrice: {
    lineHeight: 1.4,
  },
  supPrice: {
    fontSize: '.875rem',
    top: '-0.5rem',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
  },
  closeIconButton: {
    position: 'absolute',
    top: 2,
    right: 2,
    color: '#707070',
    zIndex: 1,
  },
  viewButton: {
    fontFamily: 'CircularBook, sans-serif',
  },
}));

const PriceTag = (props) => {
  const classes = useStyles();
  const { price } = props;
  const [priceSplite, setPriceSplite] = useState([]);
  useEffect(() => {
    const priceSplite = price.split('.');
    setPriceSplite(priceSplite);
  }, [price]);

  return (
    <Typography variant="h5" component="span" className={classes.productPrice}>
      {`$${priceSplite[0]}`}
      <sup className={classes.supPrice}>{`.${priceSplite[1]}`}</sup>
    </Typography>
  );
};

const ModalBodyImage = (props, ref) => {
  const classes = useStyles();
  const { poi, close } = props;
  const [product, setProduct] = useState();
  const [imgLoading, setImgLoading] = useState(true);
  const storeId = 'cotton-on-chadstone';
  const online = useOnlineStatus();
  const productHost = process.env.REACT_APP_ECOMMERCE_API_URL;

  const getProductBySku = async (sku) => {
    try {
      const res = await axios(
        `${productHost}/api/v1/Products/GetProductDetailsById?ProductId=${sku}`
      );
      if (res) {
        console.log('Data', res.data.data);
        return res.data.data;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    async function getProduct() {
      // const response = await fetch('./assets/CottonOn_SKU_References_Local.csv');
      // const reader = response.body.getReader();
      // const result = await reader.read();
      // const decoder = new TextDecoder('utf-8');
      // const csv = decoder.decode(result.value); // the csv text
      // const results = Papa.parse(csv, { header: true }); // object with { data, errors, meta }
      // const rows = results.data; // array of objects
      // let product = rows.find((x) => x.sku === poi.productId);
      // const productImage = await resourceManager.getResource(storeId, product.photo);
      // product = { ...product, photo: productImage };
      let product = await getProductBySku(poi.productId);
      // const productImage = await resourceManager.getResource(storeId, product.imagePath);
      // product = { ...product, imagePath: productImage };
      setProduct(product);
    }
    getProduct();
  }, [poi]);

  const getBrandImage = (brand) => {
    switch (brand) {
      case 'body':
        return './assets/imgs/brand-body.svg';
      case 'rubi':
        return './assets/imgs/brand-rubi.svg';
      default:
        return './assets/imgs/brand-cotton-on.svg';
    }
  };

  return (
    <Paper className={classes.paper} ref={ref}>
      <CloseIconButton className={classes.closeIconButton} onClick={close} />
      <PerfectScrollbar>
        <Box className={classes.productContainer}>
          {product ? (
            <Grid container direction="column">
              <Grid item xs={12}>
                <Box mb={0.5}>
                  <img src={getBrandImage(product.brand)} />
                </Box>
                <Typography variant="h5" gutterBottom className={classes.productName}>
                  {product.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={classes.productImageContainer}
                  style={{ display: imgLoading ? 'none' : 'block' }}
                >
                  <img
                    src={product.imagePath}
                    onLoad={() => setImgLoading(false)}
                    alt="media"
                    className={classes.productImage}
                  />
                </Box>
                <Skeleton
                  variant="rect"
                  width={285}
                  height={427}
                  style={{ display: imgLoading ? 'block' : 'none' }}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.imageDetails}>
                  <Box my={1}>
                    <PriceTag price={product.price.toString()} />
                  </Box>
                  <Box my={1}>
                    <Typography variant="body2">Product Code: {product.sku}</Typography>
                  </Box>
                  {online && (
                    <Box mt={2}>
                      <LinkButton href={product.link} target="_blank" rel="noreferrer">
                        <Typography variant="h6" className={classes.viewButton}>
                          View Details
                        </Typography>
                      </LinkButton>
                    </Box>
                  )}
                </div>
              </Grid>
            </Grid>
          ) : (
            <Box>
              <Skeleton variant="text" width={90} height={18} />
              <Skeleton variant="rect" width={285} height={400} />
              <Skeleton variant="text" width={90} height={18} />
              <Skeleton variant="text" height={16} />
              <Skeleton variant="rect" width={285} height={44} />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Paper>
  );
};

export default forwardRef(ModalBodyImage);
