import { LinearProgress, withStyles } from '@material-ui/core';

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    display: 'block',
    width: 100,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#ee1f3b',
  },
}))(LinearProgress);
