import productsRepository from 'src/apis/repositories/products.repository';

class ProductService {
  constructor() {}

  getProducts = () => {
    return productsRepository.getProducts();
  };

  getProductById = (id) => {
    return productsRepository.getProductById(id);
  };
}

export default new ProductService();
