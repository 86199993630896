import React, { useContext, useEffect, useState } from 'react';

import { Fade, makeStyles } from '@material-ui/core';

import { useProgress } from '@react-three/drei';

import useStoreContext from 'src/hooks/useStoreContext';

import '../App.css';

const useStyles = makeStyles((theme) => ({
  '@keyframes typing ': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  },
  dotContainer: {
    display: 'inline-block',
    width: 18,
    textAlign: 'left',
  },
  animateDot: {
    display: 'inline-flex',
    overflow: 'hidden',
    fontSize: 24,
    animation: `$typing 1.5s linear infinite`,
  },
}));

const SplashScreen = () => {
  const classes = useStyles();
  const { currentStoreId } = useStoreContext();
  const progressState = ['We are setting up the environment for you', 'Almost done', 'Done'];
  const [progressText, setProgressText] = useState(progressState[0]);
  const [progressNumber, setProgressNumber] = useState(0);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const { progress } = useProgress();

  let showLoadingScreenTimeout;
  let progressNumberInterval;
  
  useEffect(() => {
    if (currentStoreId) {
      setShowLoadingScreen(true);
      setProgressNumber(0);
    }
  }, [currentStoreId]);

  useEffect(() => {
    // console.log('progress', progress);

    const calculateProgressNumber = () => {
      let maxNumber = 0;
      let delay = 600;
      if (progress === 0) {
        maxNumber = 35;
      } else if (progress > 30 && progress < 75) {
        maxNumber = 75;
        delay = 500;
      } else if (progress === 100) {
        maxNumber = 100;
        delay = 25;
      }
      progressNumberInterval = setInterval(() => {
        setProgressNumber((prevProgress) =>
          prevProgress >= maxNumber ? prevProgress + 0 : prevProgress + 1
        );
      }, delay);
    };

    calculateProgressNumber();

    return () => {
      clearInterval(progressNumberInterval);
    };
  }, [progress]);

  useEffect(() => {
    return () => {
      if (!showLoadingScreen) {
        clearTimeout(showLoadingScreenTimeout);
        clearInterval(progressNumberInterval);
      }
    };
  }, [showLoadingScreen]);

  useEffect(() => {
    if (progressNumber === 80) {
      setProgressText(progressState[1]);
    } else if (progressNumber === 100) {
      setProgressText(progressState[2]);
      showLoadingScreenTimeout = setTimeout(() => {
        setShowLoadingScreen(false);
      }, 600);
    }
  }, [progressNumber]);

  return (
    <Fade in={showLoadingScreen} timeout={{ enter: 0, exit: 900 }}>
      <div className={'loadingScreen'}>
        <div
          style={{
            maxWidth: 750,
            margin: '-24px 8px 4px',
            zIndex: 1,
            position: 'relative',
          }}
        >
          <img
            src={'./assets/imgs/FIDELITY-Splash.png'}
            className="myFadeInDiv"
            style={{
              display: 'block',
              margin: 'auto',
              width: '500px',
              // height: '500px',
              maxWidth: '100%',
            }}
          />
          <p
            className="fadeInText"
            style={{
              zIndex: 2,
              position: 'absolute',
              bottom: 32,
              width: '100%',
              textAlign: 'center',
            }}
          >
            {`${progressText}`}
            <span className={classes.dotContainer}>
              <span className={classes.animateDot}>...</span>
            </span>
            {`(${progressNumber}%)`}
          </p>
        </div>
      </div>
    </Fade>
  );
};

export default SplashScreen;
