import React from 'react';
import { Link, withStyles } from '@material-ui/core';

const LinkButton = withStyles(({ spacing }) => ({
  root: {
    backgroundColor: '#333434',
    display: 'block',
    padding: spacing(0.75),
    color: 'white',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#000',
      textDecoration: 'none',
    },
    // maxWidth: 320,
  },
}))((props) => <Link {...props}>{props.children}</Link>);

export default LinkButton;
