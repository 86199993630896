import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import storesService from 'src/apis/services/stores.service';
import useStoreContext from 'src/hooks/useStoreContext';
import navigationManager from 'src/services/navigation.manager';

import LocationTiledImageCube from './LocationTiledImageCube';
import useLocationImageZoomDetails from './useLocationImageZoomDetails';

const deg2rad = (degrees) => degrees * (Math.PI / 180);

function LocationImageZoomDetailed(props) {
  const { locationData } = props;
  const lodRefs = useRef([2]);
  const [differLocationData, setDifferLocationData] = useState();
  const [visible, setVisible] = useState(true);

  const { currentStoreId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(), [currentStoreId]);

  const { setCurrentLocationId, setLODReady } = useLocationImageZoomDetails(lodRefs, props.title, [
    2,
  ]);

  useEffect(() => {
    const handleOnRequestNewLocation = () => {
      setVisible(false);
    };
    const handleOnArrived = () => {
      setVisible(true);
    };

    navigationManager.onRequestNewLocation(handleOnRequestNewLocation);
    navigationManager.onArrived(handleOnArrived);

    return () => {
      navigationManager.offRequestNewLocation(handleOnRequestNewLocation);
      navigationManager.offArrived(handleOnArrived);
    };
  }, []);

  useEffect(() => {
    setCurrentLocationId(locationData?.id);
    let setDifferTimeout = setTimeout(() => {
      if (locationData?.id) {
        setDifferLocationData({ ...locationData });
      }
    }, 50);

    return () => {
      clearTimeout(setDifferTimeout);
    };
  }, [locationData?.id]);

  const handleImageCubeReady = useCallback(
    (locationId, lodIndex) => {
      if (locationId == locationData?.id) {
        setLODReady(lodIndex);
      }
    },
    [locationData?.id]
  );

  return (
    <group
      scale={[-1, 1, 1]}
      rotation={[0, deg2rad(locationData?.degrees | 0), 0]}
      position={locationData?.position}
    >
      <Suspense fallback={null}>
        <group visible={visible}>
          <LocationTiledImageCube
            ref={(e) => {
              lodRefs.current[0] = e;
            }}
            locationData={differLocationData}
            baseImagePath={storeData.tiledImageSettings.baseUrl2k}
            segments={4}
            imageFilePrefix={'2k_face'}
            onReady={(locationId) => {
              handleImageCubeReady(locationId, 0);
            }}
          />
          <LocationTiledImageCube
            ref={(e) => {
              lodRefs.current[1] = e;
            }}
            locationData={differLocationData}
            baseImagePath={storeData.tiledImageSettings.baseUrl4k}
            segments={8}
            imageFilePrefix={'4k_face'}
            onReady={(locationId) => {
              handleImageCubeReady(locationId, 1);
            }}
          />
        </group>
      </Suspense>
    </group>
  );
}
export default LocationImageZoomDetailed;
