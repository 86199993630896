import React, { useMemo } from 'react';

import { Backdrop, Fade, Modal, makeStyles } from '@material-ui/core';

import tagService from 'src/apis/services/tag.service';
import useCameraTarget from 'src/hooks/useCameraTarget';
import useTagsStatus from 'src/hooks/useTagsStatus';

import ModalBodyImage from './ModalBodyImage';
import ModalBodyVideo from './ModalBodyVideo';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#707070',
    zIndex: 10001,
    transition: 'color 0.5s',
    '&:hover': {
      background: 'none',
    },
  },
}));

const ModalContainer = (props) => {
  const classes = useStyles();
  const { open, close } = props;
  const { currentTagId, clearActiveTag } = useTagsStatus();
  const { clearCameraTarget } = useCameraTarget();
  const tagData = useMemo(() => tagService.getTagById(currentTagId), [currentTagId]);

  const getModalBody = (tagType) => {
    switch (tagType) {
      case 'video':
        return <ModalBodyVideo poi={tagData} close={handleClose} />;
      case 'price':
        return <ModalBodyImage poi={tagData} close={handleClose} />;
      default:
        return <></>;
    }
  };

  const handleClose = () => {
    close();
    clearActiveTag();
    clearCameraTarget();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.root}
      disableAutoFocus
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open && tagData.length}>
        <>{tagData && getModalBody(tagData.type)}</>
      </Fade>
    </Modal>
  );
};

export default ModalContainer;
