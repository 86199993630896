// useCaches
import { useEffect, useState } from 'react';

import resourceManager from 'src/services/resource.manager';

export const useCaches = (storeId, sourceUrl) => {
  const [url, setUrl] = useState();

  useEffect(() => {
    const getUrl = async () => {
      const url = await resourceManager.getResource(storeId, sourceUrl);
      setUrl(url);
    };

    if (storeId) {
      getUrl();
    }
  }, [storeId, sourceUrl]);

  return url;
};
