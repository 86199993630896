import DataStorage from 'src/apis/data/DataStorage';

class TagsRepository {
  constructor() {
    this.tags = [];
    this.refreshAt;
  }

  reloadTagsIfNeeded = () => {
    if (this.refreshAt != DataStorage.refreshAt) {
      this.tags = DataStorage.tags;
      this.refreshAt = DataStorage.refreshAt;
    }
  };

  getTags = () => {
    this.reloadTagsIfNeeded();
    return this.tags;
  };

  getTagById = (id) => {
    this.reloadTagsIfNeeded();
    return this.tags?.find((x) => x.id == id);
  };
}

export default new TagsRepository();
