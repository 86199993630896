import mediaRepository from '../repositories/media.repository';

class MediaService {
  constructor() {}

  getMedias = () => {
    return mediaRepository.getMedias();
  };
}

export default new MediaService();
