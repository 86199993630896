import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';

import storesService from 'src/apis/services/stores.service';
import useBackgroundMusic from 'src/hooks/useBackgroundMusic';
import useStoreContext from 'src/hooks/useStoreContext';

import PlayToggleButton from '../styled-components/PlayToggleButton';
import VolumeSlider from '../styled-components/VolumeSlider';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 16,
    left: 20,
    zIndex: 100,
    display: 'flex',
    alignItems: 'flex-end',
  },
  reactPlayer: {},
  dialogPaper: {
    display: 'flex',
    alignItems: 'center',
  },
  backDrop: {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  textModal: {
    fontFamily: 'CircularBook, sans-serif',
  },
  buttonRoot: {
    marginTop: theme.spacing(2),
    fontFamily: 'CircularBook, sans-serif',
    letterSpacing: 2,
    color: '#ffffff',
    background: '#ee1f3b',
    fontWeight: 'bold',
    '&:hover': {
      color: '#ffffff',
      background: '#ee1f3bc9',
    },
  },
  dialogTitle: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
  },
  logo: {
    maxHeight: 54,
    maxWidth: 120,
    width: '100%',
    height: '100%',
    padding: 8,
  },
}));

const BackgroundMedia = () => {
  const classes = useStyles();
  const [url, setUrl] = useState();
  // const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.45);
  const [openModal, setOpenModal] = useState(true);
  const { isPlayed, playMusic, pauseMusic } = useBackgroundMusic();
  const backgroundMedia = {
    url: [
      '/assets/audios/Surfaces-SundayBest.mp3',
      '/assets/audios/NewRadicals-YouGetWhatYouGive.mp3',
      '/assets/audios/KungsVsCookinOn3Burners-ThisGirl.mp3',
      '/assets/audios/ChrisValentine-HighLife.mp3',
      '/assets/audios/VanceJoy-MissingPiece.mp3',
      '/assets/audios/EAST-LifeGoesOn.mp3',
    ],
    playing: false,
  };
  const { currentStoreId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(), [currentStoreId]);

  useEffect(() => {
    if (!openModal) {
      let backgroundMediaUrl = '';
      if (backgroundMedia && backgroundMedia.url !== undefined) {
        let randomNumber = random1Number();
        let mediaLength = backgroundMedia.url.length;
        let mediaIndex = randomNumber % mediaLength;
        backgroundMediaUrl = backgroundMedia.url[mediaIndex];
      }
      setUrl(backgroundMediaUrl);

      // if (backgroundMedia && backgroundMedia.playing !== undefined) {
      //   setPlaying(backgroundMedia.playing);
      // }
    }
  }, [openModal]);

  const handlePlayerReady = () => {
    console.log('handlePlayerReady');
  };
  const handlePlayerStart = () => {
    console.log('handlePlayerStart');
  };
  const handlePlayerPlay = () => {
    console.log('handlePlayerPlay');
  };
  const handlePlayerError = (e) => {
    console.log('handlePlayerError: ', e);
    // setPlaying(false);
    pauseMusic();
  };
  const handlePlayerEnded = () => {
    console.log('handlePlayerEnded');
    nextAudio();
  };

  const handleVolumeChange = (newValue) => {
    setVolume(newValue);
  };

  const nextAudio = () => {
    let prevIndex = backgroundMedia.url.indexOf(url);
    if (prevIndex + 1 < backgroundMedia.url.length) {
      setUrl(backgroundMedia.url[prevIndex + 1]);
    } else {
      setUrl(backgroundMedia.url[0]);
    }
  };

  const random1Number = () => {
    let number = Date.now();
    let lastDigitStr = String(number).slice(-1);
    const lastDigitNumber = Number(lastDigitStr);
    return lastDigitNumber;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // setPlaying(true);
    playMusic();
  };

  return (
    <>
      <div className={classes.root}>
        <ReactPlayer
          className={classes.reactPlayer}
          url={url}
          playing={isPlayed}
          // loop={true}
          volume={volume}
          width="auto"
          height="auto"
          onReady={handlePlayerReady}
          onStart={handlePlayerStart}
          onPlay={handlePlayerPlay}
          onError={handlePlayerError}
          onEnded={handlePlayerEnded}
        />

        <PlayToggleButton
          selected={isPlayed}
          onChange={() => {
            isPlayed ? pauseMusic() : playMusic();
          }}
        />
        {volume >= 0 && <VolumeSlider volume={volume} handleVolumeChange={handleVolumeChange} />}
      </div>
      <Dialog
        open={openModal}
        classes={{ paper: classes.dialogPaper }}
        disableEscapeKeyDown={true}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          disableTypography
          classes={{ root: classes.dialogTitle }}
        >
          <img
            src={storeData?.logo}
            style={{
              background: storeData?.id === 'linen-house' ? '#000' : 'white',
            }}
            className={classes.logo}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5" color="textPrimary" gutterBottom className={classes.textModal}>
            {/* Welcome to the Cotton On Experience. */}
            {`${storeData?.welcomeText}`}
          </Typography>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            classes={{ root: classes.buttonRoot }}
          >
            Continue
          </Button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default BackgroundMedia;
