import DataStorage from 'src/apis/data/DataStorage';

class ProductsRepository {
  constructor() {}

  getProducts = () => {
    return DataStorage.products;
  };

  getProductById = (id) => {
    const product = DataStorage.products.find((x) => x.id == id);
    return product;
  };
}

export default new ProductsRepository();
