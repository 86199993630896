import React from 'react';

import { usePlane } from '@react-three/cannon';

export default function GroundPlane(props) {
  const [ref] = usePlane(() => ({ type: 'Static', ...props }));
  return (
    <mesh ref={ref} {...props} castShadow receiveShadow>
      <planeBufferGeometry args={[1000, 1000]} />
      <meshStandardMaterial color="#fad390" depthWrite={false} transparent={true} opacity={0} />
    </mesh>
  );
}
