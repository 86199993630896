import React, { createContext, useCallback, useContext, useState } from 'react';

import DataStorage from 'src/apis/data/DataStorage';

const StoreContext = createContext({
  currentStoreId: '',
  setCurrentStoreId: (storeId) => {},
});

export const StoreContextProvider = ({ children }) => {
  const [storeId, setStoreId] = useState('');

  const setCurrentStoreId = useCallback(async (storeId) => {
    await DataStorage.initialize(storeId);
    setStoreId(storeId);
  }, []);

  let contextValue = {
    currentStoreId: storeId,
    setCurrentStoreId,
  };

  return <StoreContext.Provider value={contextValue}>{children}</StoreContext.Provider>;
};

const useStoreContext = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error('useStoreContext must be used within StoreContext');
  }

  return context;
};

export default useStoreContext;
