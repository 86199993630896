import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import tagService from 'src/apis/services/tag.service';
import { CurrentPositionContext } from 'src/contexts/CurrentPositionContext';

import useStoreContext from './useStoreContext';

const TagContext = createContext({
  currentTagId: '',
  activeTagIds: [],
  focusTagIds: [],
  setCurrentActiveTag: (tagId) => {},
  clearActiveTag: () => {},
  // setCurrentActiveTags: (tagIds) => {},
  checkIsInFocusArea: (tagIds) => {
    return false;
  },
});

export const TagProvider = ({ children }) => {
  const { currentStoreId } = useStoreContext();
  const tagsData = useMemo(() => tagService.getTags(), [currentStoreId]);
  const [activeTagIds, setActiveTagIds] = useState([]);
  const [focusTagIds, setFocusTagIds] = useState([]);
  const [currentTagId, setCurrentTagId] = useState();
  const [currentPositionContext] = useContext(CurrentPositionContext);
  const focusTagIdsRef = useRef();

  useEffect(() => {
    function checkTagPosition(currentPosition, tagPosition) {
      const n = 3;
      if (tagPosition < currentPosition + n && tagPosition > currentPosition - n) {
        return true;
      } else {
        return false;
      }
    }

    if (currentPositionContext && tagsData && tagsData.length) {
      let tmpId = [];
      tagsData.forEach((tag) => {
        let checkX = checkTagPosition(currentPositionContext.x, tag.position.x);
        let checkZ = checkTagPosition(currentPositionContext.z, tag.position.z);
        if (checkX && checkZ) {
          tmpId.push(tag.id);
        }
      });

      setFocusTagIds(tmpId);
      focusTagIdsRef.current = tmpId;
    }
  }, [currentPositionContext]);

  const setCurrentActiveTag = useCallback(
    (tagId) => {
      if (tagId) {
        let tmpIds = [];
        tmpIds.push(tagId);
        setActiveTagIds([...tmpIds]);
        setCurrentTagId(tagId);

        setFocusTagIds([...tmpIds]);
        // console.log('focusTagIdsRef.current', focusTagIdsRef.current);
      }
    },
    [activeTagIds]
  );

  // const setCurrentActiveTags = useCallback(
  //   (tagIds) => {
  //     if (tagIds) {
  //       setActiveTagIds([...tagIds]);

  //       setFocusTagIds([...tagIds]);
  //       // console.log('focusTagIdsRef.current', focusTagIdsRef.current);
  //     }
  //   },
  //   [activeTagIds]
  // );

  const clearActiveTag = useCallback(() => {
    setActiveTagIds((prv) => []);
    setCurrentTagId('');
    // console.log('focusTagIdsRef.current', focusTagIdsRef.current);
    setFocusTagIds(focusTagIdsRef.current);
  }, [activeTagIds]);

  const checkIsInFocusArea = (tagId) => {
    if (focusTagIdsRef.current && focusTagIdsRef.current.includes(tagId)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {}, [currentTagId]);

  let contextValue = {
    currentTagId: currentTagId,
    activeTagIds: activeTagIds,
    focusTagIds: focusTagIds,
    setCurrentActiveTag,
    clearActiveTag,
    // setCurrentActiveTags,
    checkIsInFocusArea,
  };

  return <TagContext.Provider value={contextValue}>{children}</TagContext.Provider>;
};

const useTagsStatus = () => {
  const context = useContext(TagContext);

  if (context === undefined) {
    throw new Error('useTagsStatus must be used within TagContext');
  }

  return context;
};

export default useTagsStatus;
