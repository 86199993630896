import React from 'react';

import { makeStyles } from '@material-ui/core';

import BackgroundMedia from './BackgroundMedia';
import LogoHeader from './LogoHeader';
import NavigationMenu from './NavigationMenu';
// import SliderRotation from './SliderRotation';
import SplashScreen from './SplashScreen';
import UpdateResourceButton from './UpdateResourceButton';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'black',
    position: 'relative',
  },
}));

export default function Layout(props) {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ width: '100%', height: '100%' }}>
      {props.children}
      <LogoHeader />
      <NavigationMenu />
      <BackgroundMedia />
      {/* <SliderRotation /> */}
      <SplashScreen />
      <UpdateResourceButton />
    </div>
  );
}
