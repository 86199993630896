import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import useBackgroundMusic from 'src/hooks/useBackgroundMusic';

import CloseIconButton from '../styled-components/CloseIconButton';

const useStyles = makeStyles((theme) => ({
  closeIconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#707070',
    zIndex: 10001,
    transition: 'color 0.5s',
    '&:hover': {
      background: 'none',
    },
  },
}));

const ModalBodyVideo = (props, ref) => {
  const { close, poi } = props;
  const classes = useStyles();
  const [videoLoading, setVideoLoading] = useState(true);
  const backgroundMusicPlayRef = useRef();

  const { isPlayed, playMusic, pauseMusic } = useBackgroundMusic();

  useEffect(() => {
    backgroundMusicPlayRef.current = isPlayed;
    return () => {
      if (backgroundMusicPlayRef.current) {
        playMusic();
      }
    };
  }, []);

  const handlePlayerReady = () => {
    setVideoLoading(false);
    if (isPlayed) {
      pauseMusic();
    }
  };

  return (
    <Box
      padding={3}
      sx={{ position: 'relative', background: '#fff', borderRadius: 4, minWidth: 300 }}
    >
      <CloseIconButton className={classes.closeIconButton} onClick={close} />
      <ReactPlayer
        url={poi.media.src}
        playing={true}
        width="initial"
        height="540px"
        loop
        style={{ outline: 'none' }}
        onReady={handlePlayerReady}
        controls
        volume={0.4}
      />
      <Skeleton
        variant="rect"
        width={300}
        height={540}
        style={{
          display: videoLoading ? 'block' : 'none',
          position: 'absolute',
          top: 24,
          left: 24,
        }}
      />
    </Box>
  );
};

export default forwardRef(ModalBodyVideo);
