import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router';

import Layout from './components/Layout';
import MainView from './components/MainView';
import { CameraContext } from './contexts/CameraContext';
import { CurrentPositionContext } from './contexts/CurrentPositionContext';
import { ModelContext } from './contexts/ModelContext';
import { TransitionContext } from './contexts/TransitionContext';
import { BackgroundMusicProvider } from './hooks/useBackgroundMusic';
import { CameraTargetProvider } from './hooks/useCameraTarget';
import useStoreContext, { StoreContextProvider } from './hooks/useStoreContext';
import { TagProvider } from './hooks/useTagsStatus';

export default function App() {
  const [transitionContext, setTransitionContext] = useState();
  const [cameraContext, setCameraContext] = useState();
  const [modelContext, setModelContext] = useState();
  const [currentPositionContext, setCurrentPositionContext] = useState();

  return (
    <StoreContextProvider>
      <CurrentPositionContext.Provider value={[currentPositionContext, setCurrentPositionContext]}>
        <TagProvider>
          <TransitionContext.Provider value={[transitionContext, setTransitionContext]}>
            <CameraContext.Provider value={[cameraContext, setCameraContext]}>
              <CameraTargetProvider>
                <ModelContext.Provider value={[modelContext, setModelContext]}>
                  <BackgroundMusicProvider>
                    <AppContent></AppContent>
                  </BackgroundMusicProvider>
                </ModelContext.Provider>
              </CameraTargetProvider>
            </CameraContext.Provider>
          </TransitionContext.Provider>
        </TagProvider>
      </CurrentPositionContext.Provider>
    </StoreContextProvider>
  );
}

function AppContent() {
  const { currentStoreId, setCurrentStoreId } = useStoreContext();
  const location = useLocation();

  useEffect(() => {
    let storeId = location.pathname.split('/')[1];
    const validStoreIds = process.env.REACT_APP_VALID_STORE_IDS.split(',');

    if (validStoreIds.includes(storeId)) {
      if (currentStoreId != storeId) {
        setCurrentStoreId(storeId);
      }
    } else {
      setCurrentStoreId(process.env.REACT_APP_STORE_ID);
    }
  }, [location.pathname]);

  return (
    <>
      {currentStoreId && (
        <Layout>
          <Routes>
            <Route path="/" element={<MainView />} />
            <Route path="/:storeId" element={<MainView />} />
            <Route path="/:storeId/:locationId" element={<MainView />} />
          </Routes>
        </Layout>
      )}
    </>
  );
}
