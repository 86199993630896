import React, { useContext, useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Select,
  Slide,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

import locationService from 'src/apis/services/location.service';
import storesService from 'src/apis/services/stores.service';
import useStoreContext from 'src/hooks/useStoreContext';
import navigationManager from 'src/services/navigation.manager';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    position: 'absolute',
    right: 10,
    bottom: 68,
  },
  dividerRoot: {
    backgroundColor: 'rgb(0 0 0 / 65%)',
  },
  menuRoot: {
    position: 'relative',
    height: 320,
    // overflowY: 'scroll',
  },
  department: {
    padding: 0,
    '&:first-child li': {
      marginTop: theme.spacing(0),
    },
    '&:nth-child(2) li': {
      marginTop: theme.spacing(0.5),
    },
  },
  subHeader: {
    marginTop: theme.spacing(1.5),
  },
  fontSubHeader: {
    fontWeight: 600,
    fontFamily: 'CircularBook, sans-serif',
  },
  fontHeader: {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#ee1f3b',
    fontFamily: 'CircularBook, sans-serif',
    textTransform: 'uppercase',
    '& span': {
      color: 'black',
    },
  },
  menuIconContainer: {
    position: 'absolute',
    bottom: 26,
    right: 10,
    zIndex: 100,
  },
  menuIconButton: {
    backgroundColor: '#ee1f3b',
    // borderRadius: '25%',
    padding: 4,
    border: 'none',
    color: '#fff',
    // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
    '&:hover': {
      // color: '#ee1f3b',
      // backgroundColor: 'transparent',
      backgroundColor: '#ee1f3b',
    },
  },
  animatedIconClose: {
    animation: `$myEffect 400ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedIconMenu: {
    animation: `$myEffectExit 400ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes myEffect': {
    '0%': {
      transform: 'rotateZ(90deg)',
      opacity: 0,
    },
    '100%': {
      opacity: 1,
      transform: 'rotateZ(0deg)',
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 0,
      transform: 'rotateZ(-90deg)',
    },
    '100%': {
      opacity: 1,
      transform: 'rotateZ(0deg)',
    },
  },
  formControl: {
    paddingLeft: theme.spacing(2),
    // paddingBottom: theme.spacing(0.5),
    // width: '100%',
  },
  select: {
    width: 146,
    fontSize: 14,
    fontWeight: 'bold',
    '&.MuiSelect-select:focus': {
      backgroundColor: '#fff',
    },
  },
}));

const NavigationMenu = (props) => {
  const classes = useStyles();
  const { currentStoreId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(), [currentStoreId]);
  const [selectedStore, setSelectedStore] = useState();
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (currentStoreId && currentStoreId !== selectedStore) {
      setSelectedStore(currentStoreId);
    }
  }, [currentStoreId]);

  const handleToggleMenu = () => {
    setOpenMenu((prevState) => !prevState);
  };

  const handleMenuItemClick = (locationId, options) => {
    let location = locationService.getLocationById(locationId);
    if (location) {
      navigationManager.requestNewLocation(location, false, options);
    }
  };

  const handleSelectStore = (event) => {
    setSelectedStore(event.target.value);
    window.location.replace(`${window.location.origin}/${event.target.value}`);
  };

  return (
    <>
      <Slide direction="up" in={openMenu} mountOnEnter unmountOnExit>
        <Paper className={classes.root}>
          <MenuList>
            <MenuItem button={false}>
              <Typography variant="h6" className={classes.fontHeader}>
                {/* The Cotton on */}
                {`${storeData?.name}`}
                <br />
                Experience
              </Typography>
            </MenuItem>
            <Divider variant="middle" component="li" className={classes.dividerRoot} />
            <MenuList className={classes.menuRoot}>
              <PerfectScrollbar>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="store-label"
                    id="store-select"
                    value={selectedStore}
                    label="Store"
                    classes={{ root: classes.select }}
                    onChange={handleSelectStore}
                  >
                    {/* <MenuItem value={`cotton-on-chadstone2`}>COTTON:ON Chadstone</MenuItem> */}
                    <MenuItem value={`cotton-on-westfield-geelong`}>COTTON:ON Geelong</MenuItem>
                    <MenuItem value={`cotton-on-chadstone`}>COTTON:ON Chadstone</MenuItem>
                    <MenuItem value={`factorie`}>Factorie</MenuItem>
                  </Select>
                </FormControl>
                {storeData?.menus?.length > 0 &&
                  storeData.menus.map((menuList, index) => {
                    return (
                      <MenuList key={index} className={classes.department}>
                        <MenuItem
                          {...(!menuList.locationId && { button: false })}
                          classes={{ root: classes.subHeader }}
                          {...(menuList.locationId && {
                            onClick: () =>
                              handleMenuItemClick(menuList.locationId, menuList.options),
                          })}
                        >
                          <Typography variant="body1" className={classes.fontSubHeader}>
                            {menuList.title}
                          </Typography>
                        </MenuItem>
                        {menuList.subMenus &&
                          menuList.subMenus.map((item) => {
                            return (
                              <MenuItem
                                onClick={() => handleMenuItemClick(item.locationId, item.options)}
                                key={`${item.locationId}`}
                              >
                                <Typography variant="subtitle2">{item.title}</Typography>
                              </MenuItem>
                            );
                          })}
                      </MenuList>
                    );
                  })}
              </PerfectScrollbar>
            </MenuList>
          </MenuList>
        </Paper>
      </Slide>
      <Box className={classes.menuIconContainer}>
        <IconButton disableRipple className={classes.menuIconButton} onClick={handleToggleMenu}>
          {openMenu ? (
            <CloseRoundedIcon className={classes.animatedIconClose} />
          ) : (
            <MenuRoundedIcon className={classes.animatedIconMenu} />
          )}
        </IconButton>
      </Box>
    </>
  );
};

export default NavigationMenu;
