import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, withStyles } from '@material-ui/core';

const CloseIconButton = withStyles(({ spacing }) => ({
  root: {
    padding: 0,
  },
}))((props) => (
  <IconButton {...props}>
    <CloseIcon />
  </IconButton>
));

export default CloseIconButton;
