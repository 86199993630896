import React, { memo, useMemo, useState } from 'react';
import { Vector3 } from 'three';

import { useCursor, useTexture } from '@react-three/drei';

import locationService from 'src/apis/services/location.service';
import useCameraTarget from 'src/hooks/useCameraTarget';
import useTagsStatus from 'src/hooks/useTagsStatus';
import navigationManager from 'src/services/navigation.manager';

const TagIcon = (props) => {
  const { tag } = props;
  const [hovered, setHovered] = useState(false);
  // const textRef = useRef();
  //   const vec3 = useMemo(() => new Vector3(), []);
  const [texture] = useTexture([
    tag.type === 'video' ? '/assets/imgs/play.png' : '/assets/imgs/icon-pricetag.svg',
  ]);
  // '/assets/imgs/icon-pricetag.svg'
  const { setCurrentActiveTag, checkIsInFocusArea } = useTagsStatus();
  useCursor(hovered);

  const vec3 = useMemo(() => new Vector3(), []);

  const { setCameraTargetPosition } = useCameraTarget();

  const handleClick = (e, tag) => {
    e.stopPropagation();
    let inFocusArea = checkIsInFocusArea(tag.id);
    if (inFocusArea) {
      setCurrentActiveTag(tag.id);
      setCameraTargetPosition(tag.position);
    } else {
      const handleOnceRest = () => {
        setCurrentActiveTag(tag.id);
        setCameraTargetPosition(tag.position);
      };

      let position = vec3.set(tag.position.x, tag.position.y, tag.position.z);
      let nearestLocation = locationService.getNearestLocation(position);
      navigationManager.onceRest(handleOnceRest);
      navigationManager.requestNewLocation(nearestLocation, true);
    }
  };

  const handlePointerOver = () => {
    setHovered(true);
  };

  const handlePointerOut = () => {
    setHovered(false);
  };

  return (
    // <Billboard
    //   position={[0, 0, 0.31]}
    //   follow={true}
    //   lockX={false}
    //   lockY={false}
    //   lockZ={false}
    //   // default: false
    // >
    //   <mesh scale={0.15} ref={textRef} onClick={(e) => handleTagClick(e, tag)}>
    //     <sphereGeometry />
    //     <meshStandardMaterial alphaToCoverage transparent opacity={0.5} />
    //     <Text
    //       font={'https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff'}
    //       children={'local_offer'}
    //       fontSize={1}
    //       anchorX="center"
    //       anchorY="middle"
    //       color="#ee1f3b"
    //       outlineColor="white"
    //       outlineWidth={'10%'}
    //     />
    //   </mesh>
    // </Billboard>

    <sprite
      position={[0, 0, 0.3]}
      scale={0.15}
      renderOrder={2}
      // center={[0.5, 0.5]}
      onClick={(e) => {
        handleClick(e, tag);
      }}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
    >
      <spriteMaterial transparent map={texture} sizeAttenuation={true} />
    </sprite>
  );
};

export default memo(TagIcon);
