import React, { useEffect, useRef, useState } from 'react';

import { useGLTF } from '@react-three/drei';

export function StoreModel({ url, modelId, ...props }) {
  const { nodes, materials } = useGLTF(url);
  const [geometryList, setGeometryList] = useState();
  const modelRef = useRef();

  useEffect(() => {
    if (url && nodes) {
      const keyList = Object.keys(nodes).filter((k) => {
        if (k.includes(`${modelId}_`)) return nodes[k];
      });
      const geometryArray = keyList.map((key) => nodes[key].geometry);
      setGeometryList(geometryArray);
    }
  }, [nodes]);

  return (
    <group ref={modelRef} {...props} dispose={null}>
      {geometryList &&
        geometryList.map((geometry, index) => {
          return (
            <mesh
              key={`${index}`}
              geometry={geometry}
              material-transparent={true}
              material-opacity={0}
              material-depthWrite={true}
              // material-wireframe={true}
            />
          );
        })}
    </group>
  );
}
