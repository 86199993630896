import React, { useContext, useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

import storesService from 'src/apis/services/stores.service';
import useStoreContext from 'src/hooks/useStoreContext';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 100,
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, 0)',
    top: 10,
  },
}));

const LogoHeader = () => {
  const classes = useStyles();
  const { currentStoreId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(), [currentStoreId]);

  return (
    <div className={classes.root}>
      <img src={storeData?.logo} alt="brand-logo" width="177" />
    </div>
  );
};

export default LogoHeader;
