import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

const BackgroundMusicContext = createContext({
  isPlayed: false,
  pauseMusic: () => {},
  playMusic: () => {},
});

export const BackgroundMusicProvider = ({ children }) => {
  const [isPlayed, setIsPlayed] = useState(false);

  const pauseMusic = useCallback(() => {
    setIsPlayed(false);
  }, [isPlayed]);

  const playMusic = useCallback(() => {
    setIsPlayed(true);
  }, [isPlayed]);

  let contextValue = {
    isPlayed: isPlayed,
    pauseMusic,
    playMusic,
  };

  return (
    <BackgroundMusicContext.Provider value={contextValue}>
      {children}
    </BackgroundMusicContext.Provider>
  );
};

const useBackgroundMusic = () => {
  const context = useContext(BackgroundMusicContext);

  if (context === undefined) {
    throw new Error('useAccounts must be used within AccountContext');
  }

  return context;
};

export default useBackgroundMusic;
